import { isDev } from 'env';
import React from 'react';
import { toJS } from 'mobx';
import { Provider } from 'mobx-react';
import { create } from 'mobx-persist';

import { appRoutes, appRouter } from 'routes';

import i18n from 'Translation/Setup';
import app from 'State/AppService';
import workflow from 'State/WorkflowService';
import errorHandler, { RouteNotFoundError } from 'State/ErrorHandler';
import DevTools from 'Components/Layouts/Wrapper/DevTools';
import Router from 'Components/Router';
import ErrorBoundary from 'Components/Layouts/Wrapper/ErrorBoundary';
import InitialLoadingScreenRemover from 'Components/Layouts/Wrapper/InitialLoadingScreenRemover';

Promise.all([i18n, create({})('workflowService', workflow), create({})('appService', app)])
  .then(() => {
    isDev && logHydratedStates();
    connectRouterWithStates();
    startRouter();
  })
  .catch(e => {
    console.warn('App failed to start.', e);
    errorHandler.catchError(e);
  });

const App = ({language}: {language: string | null}) => {
  if (!!language) {
    workflow.setLanguage(language);
  }
  return (
    <Provider {...{ app, workflow, errorHandler }}>
      <InitialLoadingScreenRemover>
        <ErrorBoundary>
          <DevTools>
            <Router />
          </DevTools>
        </ErrorBoundary>
      </InitialLoadingScreenRemover>
    </Provider>
  );
};
function connectRouterWithStates() {
  workflow.navigate = (step: string, replace: boolean = false) => {
    console.log('Navigating to ', appRouter.buildUrl('workflowStep', { step }));
    appRouter.navigate('workflowStep', { step }, { replace });
  };

  appRouter.subscribe(state => {
    app.activateLoader();

    app.setState(state.route);

    if (state.route.name === appRoutes.WORKFLOW_ENTRY.name) {
      workflow.updateStep(Object.keys(workflow.getWorkflowSteps())[0], state.route.params.data);
    } else if (state.route.name === appRoutes.WORKFLOW_STEP.name) {
      workflow.updateStep(state.route.params.step, null);
    } else {
      workflow.updateStep(null, null);
    }

    if (app.state.name === appRoutes.ROUTE_NOT_FOUND.name) {
      errorHandler.catchError(new RouteNotFoundError());
    } else {
      errorHandler.clear();
    }
  });
}

function startRouter() {
  appRouter.start();
}

function logHydratedStates() {
  console.log('Hydrated WorkflowService:', toJS(workflow));
  console.log('Hydrated AppService:', toJS(app));
  console.log('Hydrated AppConfig:', toJS(app.config));
}

export default App;
